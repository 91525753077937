import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Button, Input, Form, Spin } from "antd";
import { debounce } from "lodash";
import { AiOutlineFolderAdd, AiOutlineFileAdd } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri"
import Notification from '../ant-notification/notification';

import { CloudUploadOutlined } from "@ant-design/icons";

import SearchInput from "../../components/searchBar/input";
import Card from "./Card";
import FolderButton from "./FolderButton";

import { TemplateStyleWrapper, MessageModalWrapper } from "./style";
import { AddFolder, GetFolders, SetState, RemoveFolder, getTemplates, AddTemplate, RemoveTemplate, updateTemplate } from "../../redux/slices/template-slice";
const Template = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading, listsLoading, templates, folders, err, success, message, total } = useSelector(state => state.template);
  const [CreateTemplate, setCreateTemplate] = useState(false);
  const [createFolder, setCreateFolder] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [deleteFolder, setDeleteFolder] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState(false);
  const [foldersData, setFoldersData] = useState([]);
  const [templatesData, setTemplatesData] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [messageData, setMessageData] = useState('');
  const [folderId, setFolderId] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState([["createdAt", "ascending"]]);
  const [files, setFiles] = useState([]);

  const showModal = () => {
    setTemplateTitle('');
    setMessageData('');
    setCreateTemplate(true);
  };
  const handleOk = () => {
    if (!templateTitle || templateTitle?.trim()?.length === 0) return dispatch(SetState({ field: 'err', value: 'Please Enter Proper Title' }))
    if (!messageData || messageData?.trim()?.length === 0) return dispatch(SetState({ field: 'err', value: 'Please Enter Proper message' }));
    dispatch(AddTemplate({ message: messageData, name: templateTitle, folderId })).then((data) => {
      if (data?.payload?.data) {
        setFolderId(data?.payload?.data?.folderId);
      }
    });
    setMessageData('');
    setTemplateTitle("");
    setCreateTemplate(false);
  };
  const handleCancel = () => {
    setCreateTemplate(false);
  };

  const handleAddFolder = async () => {
    if (!folderName || folderName?.trim()?.length === 0) return dispatch(SetState({ field: 'err', value: 'Please Enter Proper Name' }))
    setCreateFolder(false);
    dispatch(AddFolder(folderName));
    setFolderName('');
  }
  const { TextArea } = Input;

  useEffect(() => {
    setFoldersData(folders);
    setTemplatesData(templates);

  }, [folders, templates]);
  useEffect(() => {
    if (success) {
      Notification({
        type: 'success',
        message
      });
      dispatch(SetState({ field: 'success', value: false }))
      dispatch(GetFolders({ filter, sort })).then((foldersData) => {
        if (foldersData?.payload?.data.length > 0) {

          const firstFolderId = foldersData?.payload?.data[0]._id;
          // dispatch(getTemplates({ id: firstFolderId }));
          setFolderId(firstFolderId);
        }
      });
      dispatch(getTemplates({ id: folderId }))
    }
    if (err) {
      Notification({
        type: 'error',
        message: err
      });
      dispatch(SetState({ field: 'err', value: '' }))

    }
    setCreateTemplate(false);
  }, [success, err])
  useEffect(() => {
    dispatch(GetFolders({ filter, sort })).then((foldersData) => {
      if (foldersData?.payload?.data.length > 0) {

        const firstFolderId = foldersData?.payload?.data[0]._id;
        dispatch(getTemplates({ id: firstFolderId }));
        setFolderId(firstFolderId);
      }
    });
  }, []);
  useEffect(() => {
    form.setFieldsValue({ templateTitle: templateTitle, messageData: messageData })
  }, [form, templateTitle, messageData])
  const handleDeleteClick = (id) => {
    setFolderId(id)
    setDeleteFolder(true);
  };
  const handleFolderClick = (id) => {
    setFolderId(id);
    dispatch(getTemplates({ id }))
  };

  const handleTemplateDelete = (id) => {
    setTemplateId(id)
    setDeleteTemplate(true)
  }

  const setCurrentTemplate = async (id) => {
    const template = templates?.filter((template) => template._id == id);
    setTemplateTitle(template[0]?.name);
    setMessageData(template[0]?.message)
  }


  const handleUpdateTemplate = () => {
    dispatch(updateTemplate({ message: messageData, name: templateTitle, templateId }))
    setEditTemplate(false)
  }

  const handleSearchChange = debounce((event) => {
    setFilter({ keyword: event });
    dispatch(GetFolders({ filter: { keyword: event }, sort }))
  }, 250);

  const addToTextArea = function (text_to_add) {
    let textArea = ref.current?.resizableTextArea?.textArea

    let start_position = textArea?.selectionStart;
    let end_position = textArea?.selectionEnd;

    let message = `${textArea?.value.substring(
      0,
      start_position
    )}${text_to_add}${textArea?.value.substring(
      end_position,
      textArea?.value.length
    )}`;
    setMessageData(message);
  };

  const handleFileUpload = (e) => {
    let images = [];
    const uploadedFiles = e.target.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
      images.push(uploadedFiles[i]);
    }
    setFiles([...images]);
  };

  const RemoveFileHandler = () => {
    setFiles([]);
  };

  return (
    <TemplateStyleWrapper>
      <div className="header-wrapper">
        <h1>Messaging</h1>
        <div className="search_wrapper">
          <SearchInput
            onChange={(event) => {
              handleSearchChange(event.target.value);
            }}
            type="text"
            search
            placeholder="Search by Name"
          />
        </div>
      </div>
      <div className="content-templates-overaly">
        <Row gutter={30}>
          <Col md={8}>
            <div className="tabs-wrapper">
              <div className="tabs-header">
                <h2>Message Groups</h2>
                <AiOutlineFolderAdd onClick={() => setCreateFolder(true)} />
              </div>
              <div className="buttons-wrapper">
                {loading ? (
                  <div className="folder-loading">
                    <Spin size="large" />
                  </div>
                ) : (
                  <>
                    {foldersData?.map((folder, index) => {
                      return (
                        <FolderButton
                          text={folder?.name}
                          onClick={() => handleFolderClick(folder?._id)}
                          handleDeleteFolder={() =>
                            handleDeleteClick(folder?._id)
                          }
                          active={folder?._id == folderId ? true : false}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Modal
            title="New Message"
            className="create-template-modal"
            centered
            width={628}
            visible={CreateTemplate}
            onOk={handleOk}
            okText="Create"
            cancelText="Cancel"
            onCancel={handleCancel}
          >
            <div className="create_Template">
              <Form form={form} layout="vertical">
                <Form.Item
                  label="Title"
                  name="templateTitle"
                  rules={[
                    {
                      required: true,
                      message: "Please input the folder name!",
                    },
                  ]}
                >
                  <Input
                    className="border-radius-none"
                    value={templateTitle}
                    onChange={(e) => setTemplateTitle(e.target.value)}
                    placeholder="Title Here"
                  />
                </Form.Item>
                {/* <div className="text_area_wrapper">
                    <Button className="add-reciption-btn" icon="+"
                    onClick={() => addToTextArea(' {name} ')}
                    >
                    Add Recipient Name
                    </Button>
                    </div> */}
                <Form.Item
                  label="Message"
                  name="messageData"
                  rules={[
                    { required: true, message: "Please input the message!" },
                  ]}
                >
                  <TextArea
                    ref={ref}
                    value={messageData}
                    onChange={(e) => setMessageData(e.target.value)}
                    rows={8}
                    placeholder="Message Here"
                  />
                </Form.Item>
                <div className="d-flex">
                  <Button
                    onClick={() => addToTextArea(" {first_name} ")}
                    size="small"
                  >
                    {"{first_name}"}
                  </Button>
                  <Button
                    onClick={() => addToTextArea(" {last_name} ")}
                    size="small"
                  >
                    {"{last_name}"}
                  </Button>
                  <Button
                    onClick={() => addToTextArea(" {name} ")}
                    size="small"
                  >
                    {"{name}"}
                  </Button>
                </div>
                <span
                  style={{ display: "block", marginTop: "8px", color: "#888" }}
                >
                  Tip: It's preferred to use a Spintax format to create dynamic
                  and randomized messages.
                  <br />
                  (e.g.,{" "}
                  {
                    "{Hello|Hi|Hey} {friend|buddy|mate}, how are you {doing|feeling} today?"
                  }
                  )
                </span>

                <Form.Item
                  label="Upload Images"
                  name="uploadImages"
                  style={{marginTop:"16px", marginBottom: "0px"}}
                  // rules={[
                  //   { required: true, message: "Please input" },
                  // ]}
                >
                  <MessageModalWrapper>
                    {files.length ? (
                      <div
                        onClick={RemoveFileHandler}
                        className="upload-overlay-wrapper"
                      >
                        <AiFillFile
                          style={{ color: "#5f63f2", fontSize: "20px" }}
                        />
                        <p>{files?.length} files</p>
                        <Button>Remove File</Button>
                      </div>
                    ) : (
                      <div className="upload-overlay-wrapper">
                        <CloudUploadOutlined className="new-condition-icon" />
                        <p className="new-condition">
                          Click to add file on this area to upload
                        </p>
                        <Input
                          type="file"
                          className="upload-button"
                          multiple
                          accept="image/png, image/jpeg"
                          onChange={handleFileUpload}
                        />
                      </div>
                    )}
                  </MessageModalWrapper>
                </Form.Item>
              </Form>
            </div>
          </Modal>
          <Modal
            title="Edit Message"
            className="create-template-modal"
            centered
            width={628}
            visible={editTemplate}
            onOk={handleUpdateTemplate}
            okText="Confirm"
            cancelText="Cancel"
            onCancel={() => setEditTemplate(false)}
          >
            <div className="create_Template">
              <Form
                form={form}
                layout="vertical"
                initialValues={{
                  templateTitle: templateTitle,
                  messageData: messageData,
                }} // Initialize the form with initial values
              >
                <Form.Item
                  label="Title"
                  name="templateTitle"
                  rules={[
                    { required: true, message: "Please input the Title!" },
                  ]}
                >
                  <Input
                    className="border-radius-none"
                    placeholder="Title Here"
                    onChange={(e) => setTemplateTitle(e.target.value)}
                  />
                </Form.Item>
                {/* <div className="text_area_wrapper" onClick={() => addToTextArea(' {name} ')}>
                  <Button className="add-reciption-btn" icon="+"  >
                    Add Recipient Name
                  </Button>
                </div> */}
                <Form.Item
                  label="Message"
                  name="messageData"
                  rules={[
                    { required: true, message: "Please input the message!" },
                  ]}
                >
                  <TextArea
                    ref={ref}
                    rows={8}
                    placeholder="Message Here"
                    onChange={(e) => setMessageData(e.target.value)}
                  />
                </Form.Item>
                <div className="d-flex">
                  <Button
                    onClick={() => addToTextArea(" {first_name} ")}
                    size="small"
                  >
                    {"{first_name}"}
                  </Button>
                  <Button
                    onClick={() => addToTextArea(" {last_name} ")}
                    size="small"
                  >
                    {"{last_name}"}
                  </Button>
                  <Button
                    onClick={() => addToTextArea(" {name} ")}
                    size="small"
                  >
                    {"{name}"}
                  </Button>
                </div>
                <span
                  style={{ display: "block", marginTop: "8px", color: "#888" }}
                >
                  Tip: It's preferred to use a Spintax format to create dynamic
                  and randomized messages.
                  <br />
                  (e.g.,{" "}
                  {
                    "{Hello|Hi|Hey} {friend|buddy|mate}, how are you {doing|feeling} today?"
                  }
                  )
                </span>
              </Form>
            </div>
          </Modal>
          <Modal
            title="Create Message Group"
            className="create-template-modal"
            centered
            width={628}
            visible={createFolder}
            onOk={handleAddFolder}
            okText="Create"
            cancelText="Cancel"
            onCancel={() => setCreateFolder(false)}
          >
            <div className="create_Template">
              {/* <label>Folder Name</label>
              <Input placeholder="Folder Name Here" /> */}

              <Form form={form} layout="vertical">
                <Form.Item
                  label="Title"
                  rules={[
                    { message: "Please Input Folder Name!", required: true },
                  ]}
                >
                  <Input
                    className="border-radius-none"
                    name="folderName"
                    value={folderName}
                    onChange={(e) => {
                      setFolderName(e.target.value);
                    }}
                    placeholder="Folder Name Here"
                  />
                </Form.Item>
              </Form>
            </div>
          </Modal>
          <Modal
            className="delete-modal-overlay-wrapper"
            centered
            width={440}
            closable={false}
            visible={deleteFolder}
            onOk={() => {
              dispatch(RemoveFolder({ folderId }));
              setDeleteFolder(false);
            }}
            okText="Delete"
            cancelText="Cancel"
            onCancel={() => setDeleteFolder(false)}
          >
            <RiDeleteBinLine />
            <h2>Are you sure you want to delete this message group?</h2>
            <p>This message group will be deleted from the system.</p>
          </Modal>
          <Modal
            className="delete-modal-overlay-wrapper"
            centered
            width={440}
            closable={false}
            visible={deleteTemplate}
            onOk={() => {
              dispatch(RemoveTemplate({ templateId }));
              setDeleteTemplate(false);
            }}
            okText="Delete"
            cancelText="Cancel"
            onCancel={() => setDeleteTemplate(false)}
          >
            <RiDeleteBinLine />
            <h2>Are you sure you want to delete this message?</h2>
            <p>This message will be deleted from the system.</p>
          </Modal>
          <Col md={16}>
            <div className="template-content-wrapper">
              <div className="template-content-header">
                <h3>Messages</h3>
                <Button
                  disabled={!folders?.length}
                  onClick={showModal}
                  icon={<AiOutlineFileAdd />}
                >
                  Create Message
                </Button>
              </div>
              <div className="data-Template-wrapper">
                <Row gutter={[30, 30]}>
                  {listsLoading ? (
                    <div className="folder-loading">
                      <Spin size="large" />
                    </div>
                  ) : (
                    <>
                      {templates?.map((template, index) => {
                        return (
                          <Col md={12} key={index}>
                            <Card
                              headingText={template?.name}
                              boxText={template?.message}
                              editClick={async () => {
                                setTemplateId(template?._id);
                                await setCurrentTemplate(template?._id);
                                setEditTemplate(true);
                              }}
                              deleteClick={() =>
                                handleTemplateDelete(template?._id)
                              }
                            />
                          </Col>
                        );
                      })}
                    </>
                  )}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </TemplateStyleWrapper>
  );
};

export default Template;
